import motoverte from '@amo/core/assets/images/produits/icon_cross.svg';
import moto from '@amo/core/assets/images/produits/icon_moto.svg';
import quad from '@amo/core/assets/images/produits/icon_quad.svg';
import scooter from '@amo/core/assets/images/produits/icon_scooter.svg';
import { Modal, QuestionLabel, SimpleTitle } from '@amo/core/components/containers';
import { Radio, Text } from '@amo/core/components/forms';
import { required } from '@amo/core/utils/validateField';
import classNames from 'classnames';
import { useState } from 'react';
import { Field } from 'redux-form';
import QuestionContentContainer from '../../QuestionContent/QuestionContentContainer';
import SearchBarVehiculeContainer from '../../Vehiculier/SearchBarVehicule/SearchBarVehiculeContainer';
import VehiculierContainer from '../../Vehiculier/VehiculierContainer';
import BlocInformationsContainer from './BlocInformations/BlocInformationsContainer';
import moment from "moment";
import Swal from "sweetalert2";

const Vehicule = (props) => {
    const {
        vehiculeIsDisabled,
        marque,
        modele,
        cylindree,
        familleProduit,
        changeValue,
        postDejaClient,
        loadingDejaClient,
        handleSubmit,
        nextStep,
        fillLoading,
        valid,
        dejaClientValide,
        dejaClientNom,
        dejaClientPrenom,
        dejaClientBirthdate
    } = props;
    const [vehiculierAffiche, setVehiculierAffiche] = useState(false);
    const [previousVehicule, setPreviousVehicule] = useState(familleProduit);
    const [showClientConfirmation, setShowClientConfirmation] = useState(false);
    const [isClientConfirmed, setIsClientConfirmed] = useState(null);

    const handleClientIdentification = async () => {
        setIsClientConfirmed(null);
        changeValue('clientConfirmation', null)
        const result = await postDejaClient();
        if (result) {
            setShowClientConfirmation(true);
        }
    };

    const handleClientConfirmation = (value) => {
        setIsClientConfirmed(value);

        if (value === '0') {
            resetClientData();
            setShowClientConfirmation(false); // Masquer la confirmation de client
            changeValue('dejaClientValide', false); // Réinitialiser le flag de validation
            Swal.fire({
                icon: 'info',
                confirmButtonText: "J'ai bien compris",
                html: '<p>Pour information, suite à votre réponse négative concernant l’identification du client, nous vous informons que nous vous présenterons un formulaire vide sans aucune information client.</p>',
            });
        }
    };

    const resetClientData = () => {
        changeValue('DemandeTarif[ListePersonnes][0]', {});

        changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][BirthCity]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][BirthCountry]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][BirthDepartment]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][BirthName]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][CP]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][Civilite]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][ConducteurMineur]', false);
        changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][DateNaissance]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][DejaClientAntecedents]', []);
        changeValue('DemandeTarif[ListePersonnes][0][Email]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', null);
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', null);
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', null);
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', "24");
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', "24");
        changeValue('DemandeTarif[ListePersonnes][0][Nom]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][NumPermis]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][Prenom]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][RoleConducteur]', "P");
        changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', true);
        changeValue('DemandeTarif[ListePersonnes][0][TelPortable]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][TypePersonne]', "P");
        changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', null);
        changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', null);
        changeValue('assure_auto', null);
        changeValue('assure_moto', null);
        changeValue('DemandeTarif[ListePersonnes][0][Ville]', undefined);

        // Supprimer les informations client confirmées
        changeValue('clientConfirmation', '');
        changeValue('dejaClientValide', false);
        changeValue('dejaClientNom', '');
        changeValue('dejaClientPrenom', '');
        changeValue('dejaClientBirthdate', '');
    };


    const changeVehicule = (value) => {
        if (value !== previousVehicule) {
            setPreviousVehicule(value);
            changeValue('marqueIdVehicule', '');
            changeValue('marqueVehicule', '');
            changeValue('modeleVehicule', '');
            changeValue('code_categorie', '');
            changeValue('DemandeTarif[Vehicule][UsageVehicule]', null);

            if (value === '50') {
                changeValue('cylindreeVehicule', 50);
            } else {
                changeValue('cylindreeVehicule', '');
            }
        }
    };

    return (
        <>
            <div className={'mb-5'}>
                {
                    <div className="mt-3">
                        <QuestionLabel>Indiquez votre numéro de client ou de contrat&nbsp;:</QuestionLabel>
                        <div className="row">
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field component={Text} name={`DemandeTarif[ListePersonnes][0][NumeroClient]`}
                                       placeholder={'N° client/contrat'}/>
                            </div>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <button
                                    className={`btn btn-primary medium-rounded mb-2 text-uppercase ${dejaClientValide && 'disabled'}`}
                                    onClick={handleClientIdentification}
                                    disabled={dejaClientValide}>
                                    {loadingDejaClient ? (
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"/>
                                    ) : (
                                        "m'identifier"
                                    )}
                                </button>
                            </div>
                        </div>

                        {dejaClientValide && isClientConfirmed !== '0' && (
                            <div className="mt-3">
                                Nous avons bien identifié le client : <strong>{dejaClientNom} {dejaClientPrenom}</strong>, né(e) le <strong>{moment(dejaClientBirthdate, 'DD/MM/YYYY').isValid() ? moment(dejaClientBirthdate, 'DD/MM/YYYY').format('DD/MM/YYYY') : 'Date invalide'}</strong>.
                                <QuestionLabel>Est-ce bien lui/elle&nbsp;?</QuestionLabel>
                                <div className="row">
                                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'clientConfirmation'}
                                            value={'1'}
                                            type={'radio'}
                                            label={'Oui'}
                                            onChange={() => handleClientConfirmation('1')}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'clientConfirmation'}
                                            value={'0'}
                                            type={'radio'}
                                            label={'Non'}
                                            onChange={() => handleClientConfirmation('0')}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}


                        <div className={'bg-warning p-4 rounded text-left mt-3 f-14'}>
                            <p className={'mb-0'}>
                                Le prospect est <strong>déjà client et souhaite modifier son contrat existant</strong>
                                (changement de véhicule à assurer, revue des garanties, revue de prix,…), vous devez le
                                rediriger vers ce numéro : 02 47 51 07 07 (Touche 1)
                            </p>
                        </div>
                    </div>
                }
            </div>
            <hr className={'mt-5 mb-5'} />
            <div>
                <SimpleTitle>Votre véhicule</SimpleTitle>
                <QuestionContentContainer name={'DemandeTarif[Vehicule][SituationVehicule]'}>
                    <QuestionLabel required>
                        Est-ce que vous possédez déjà votre deux-roues&nbsp;?
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][SituationVehicule]'}
                                value={'1'}
                                type={'radio'}
                                label={'Oui'}
                                onChange={() => changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', null)}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][SituationVehicule]'}
                                value={'0'}
                                type={'radio'}
                                label={'Non'}
                                onChange={() => {
                                    changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', '0');
                                    //Comme moins de 3 mois on par du principe qu'il a été assurés
                                    changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '1');
                                }}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>

                <QuestionContentContainer name={'typeVehicule'}>
                    <QuestionLabel required>
                        Quel est le type de 2-roues que vous souhaitez assurer&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'moto'}
                                type={'radio'}
                                label={'Moto'}
                                icon={moto}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'scooter'}
                                type={'radio'}
                                label={'Scooter'}
                                icon={scooter}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'50'}
                                type={'radio'}
                                label={'Cyclo 50 cm3'}
                                icon={scooter}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'quad'}
                                type={'radio'}
                                label={'Quad'}
                                icon={quad}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'typeVehicule'}
                                value={'verte'}
                                type={'radio'}
                                label={'Moto verte'}
                                icon={motoverte}
                                validate={required}
                                onChange={(e) => changeVehicule(e.target.value)}
                                className={'text-center f-14'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>

                <QuestionContentContainer name={'modeleVehicule'}>
                    <QuestionLabel required>
                        Quel est le modèle de votre 2-roues&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className="row" style={{ alignItems: 'flex-start' }}>
                        {modele && (
                            <div className={'mt-3 font-weight-bold text-primary-light f-14 col-12'}>
                                <span className={'text-initial'}>Votre véhicule :</span>{' '}
                                <span dangerouslySetInnerHTML={{ __html: `${marque} ${cylindree} ${modele}` }} />
                            </div>
                        )}
                        {familleProduit && <SearchBarVehiculeContainer />}

                        <div className={'col-12 col-md-5 mt-2 mt-md-2'}>
                            <Radio
                                label={!modele ? 'Sélectionnez votre modèle' : 'Modifiez votre modèle'}
                                type={'checkbox'}
                                input={{
                                    onChange: () => setVehiculierAffiche(!vehiculierAffiche),
                                    checked: !!marque,
                                }}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>

                {vehiculierAffiche && (
                    <Modal
                        bodyScrollable
                        onClick={() => {
                            setVehiculierAffiche(!vehiculierAffiche);
                        }}
                        large
                        title={'Sélectionnez votre véhicule'}
                    >
                        <VehiculierContainer
                            inputName={'DemandeTarif[Vehicule]'}
                            setVehiculierAffiche={setVehiculierAffiche}
                        />
                    </Modal>
                )}

                {vehiculeIsDisabled && (
                    <div className={'bg-danger p-4 rounded text-left mt-2 f-14'}>
                        <p
                            className={'m-0'}
                            dangerouslySetInnerHTML={{
                                __html: `Attention, nous n'assurons pas ce type de véhicule. (${marque} ${cylindree} ${modele})`,
                            }}
                        />
                    </div>
                )}

                { !vehiculeIsDisabled && <BlocInformationsContainer cylindree={cylindree} /> }
            </div>
            <div className={'submit-row mt-5 text-right'}>
                <div className={'submit-row mt-5 text-right'}>
                    <button
                        className={classNames(
                            (!valid || vehiculeIsDisabled || (dejaClientValide && isClientConfirmed === '0')) && 'disabled',
                            'btn btn-primary medium-rounded mb-2 order-lg-1',
                        )}
                        onClick={(!valid || vehiculeIsDisabled || (dejaClientValide && isClientConfirmed === '0')) ? null : handleSubmit(nextStep)}
                        data-id="btn-next"
                    >
                        {fillLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                        ) : (
                            'Continuer'
                        )}
                    </button>
                </div>

            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>
        </>
    );
};

export default Vehicule;
